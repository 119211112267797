<template>
    <CompTable ref="comp_table" title="注册用户" :dataBefore="onDataBefore" :columns="getColumns()" :dataList="dataList" @on-reset="onReset">
        <template #operate>
            <Button type="info" @click="$refs.comp_table.export()">导出</Button>
        </template>
        <template #search="evt">
            <RadioGroup v-model="evt.search.onlineType" type="button">
                <Radio label="0">全部示范社区</Radio>
                <Radio label="1">市级示范社区</Radio>
                <Radio label="2">区级示范社区</Radio>
                <Radio label="3">街镇级示范社区</Radio>
            </RadioGroup>
            <DatePicker type="date" v-model="evt.search.date" placeholder="选择日期" style="width: 300px" />
        </template>
    </CompTable>
</template>

<script>
import CompTable from "@/views/jointly/components/comp-table.vue"
import Request from "../../jointly/utils/request"
import Utils from "../../jointly/utils/utils"

export default {
    components: {
        CompTable,
    },

    data() {
        return {
            level: 3,
            dataList: null,
        }
    },

    created() {
        this.onLoadData()
    },

    methods: {
        async onDataBefore(data) {
            console.log(data)
            const res = {}

            if (data.onlineType) {
                res.onlineType = data.onlineType === "0" ? "" : data.onlineType
            }

            if (data.date) {
                res.statDate = this.$core.formatDate(new Date(data.date), "yyyy-MM-dd")
            }

            if (Object.keys(res).length > 0) {
                this.onLoadData(res)
            }

            return false
        },

        onReset() {
            this.onLoadData()
        },

        getColumns() {
            return [
                {
                    title: "区",
                    key: "zoneName",
                    render: (h, p) => {
                        return h(
                            "p",
                            {
                                style: {
                                    color: "#2faaf7",
                                    cursor: "pointer",
                                },
                                on: {
                                    click: () => {
                                        if (this.level !== 3) {
                                            this.level = 3
                                            this.dataList = this.tree
                                        } else {
                                            this.level = 4
                                            this.dataList = this.tree.find(c => c.zoneName === p.row.zoneName).child
                                        }
                                    },
                                },
                            },
                            p.row.zoneName
                        )
                    },
                },
                ...(this.level >= 4
                    ? [
                          {
                              title: "街道",
                              align: "center",
                              key: "streetName",
                              render: (h, p) => {
                                  return h(
                                      "p",
                                      {
                                          style: {
                                              color: "#2faaf7",
                                              cursor: "pointer",
                                          },
                                          on: {
                                              click: () => {
                                                  if (this.level !== 4) {
                                                      this.level = 4
                                                      this.dataList = this.tree.find(c => c.zoneName === p.row.zoneName).child
                                                  } else {
                                                      this.level = 5
                                                      this.dataList = this.tree.find(c => c.zoneName === p.row.zoneName).child.find(c => c.streetName === p.row.streetName).child
                                                  }
                                              },
                                          },
                                      },
                                      p.row.streetName
                                  )
                              },
                          },
                      ]
                    : []),
                ...(this.level >= 5
                    ? [
                          {
                              title: "社区",
                              align: "center",
                              key: "communityName",
                          },
                      ]
                    : []),
                {
                    title: "社区访问用户 (人)",
                    key: "userView",
                    width: 180,
                    sum: true,
                },
                {
                    title: "社区注册用户 (人)",
                    key: "userRegister",
                    width: 180,
                    sum: true,
                },
                {
                    title: "社区注册用户覆盖户数",
                    key: "uabaseActiveHouseTotal",
                    width: 180,
                    sum: true,
                },
                {
                    title: "家庭户代表代表总人数",
                    key: "representUser",
                    width: 180,
                    sum: true,
                },
                {
                    title: "社区居民总数",
                    key: "uabase",
                    width: 180,
                    sum: true,
                },
                {
                    title: "社区居民有手机号总数",
                    key: "uabaseMobile",
                    width: 180,
                    sum: true,
                },
            ]
        },

        async onLoadData(data = {}) {
            if (!data.statDate) {
                data.statDate = this.$core.formatDate(new Date(), "yyyy-MM-dd")
            }

            return Request.get("/gateway/api/symanage/pc/usbase/queryUaBaseByAll", {
                orgLevel: 5,
                ...data,
            }).then(res => {
                if (!res) {
                    return this.$Message.error("获取数据失败")
                }

                const tree = []
                const zones = this.groupBy(res, "zoneName")

                // 处理区
                Utils.eachObj(zones, (zk, zv) => {
                    const streets = this.groupBy(zv, "streetName")
                    const streetList = []

                    Utils.eachObj(streets, (sk, sv) => {
                        const street = {
                            streetName: sk,
                            zoneName: zk,
                            userView: this.countBy(sv, "userView"),
                            userRegister: this.countBy(sv, "userRegister"),
                            uabase: this.countBy(sv, "uabase"),
                            uabaseActiveHouseTotal: this.countBy(sv, "uabaseActiveHouseTotal"),
                            uabaseMobile: this.countBy(sv, "uabaseMobile"),
                            representUser: this.countBy(sv, "representUser"),
                            child: sv,
                        }

                        streetList.push(street)
                    })

                    const zone = {
                        zoneName: zk,
                        userView: this.countBy(zv, "userView"),
                        userRegister: this.countBy(zv, "userRegister"),
                        uabase: this.countBy(zv, "uabase"),
                        uabaseActiveHouseTotal: this.countBy(zv, "uabaseActiveHouseTotal"),
                        uabaseMobile: this.countBy(zv, "uabaseMobile"),
                        representUser: this.countBy(zv, "representUser"),
                        child: streetList,
                    }

                    tree.push(zone)
                })

                this.tree = tree
                this.dataList = tree
                this.level = 3
            })
        },

        countBy(arr, key) {
            var q = 0

            Utils.each(arr, v => {
                q += v[key] || 0
            })

            return q
        },

        groupBy(arr, key) {
            return arr.reduce((result, obj) => {
                const group = obj[key]
                if (!result[group]) {
                    result[group] = []
                }
                result[group].push(obj)
                return result
            }, {})
        },
    },
}
</script>

<style></style>
